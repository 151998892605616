<template>
	<BaseLayout>
		<Modal v-if="showModal" :title="$t('help.helpCenter')" @close="onClose">
			<div class="p-5">
				<p class="pb-2 text-lg font-black">{{ question.name }}</p>
				<p class="whitespace-pre-wrap text-sm">{{ question.description }}</p>
			</div>
		</Modal>

		<div v-else>
			<Toolbar @clickLeft="$router.push('/account')">
				<template v-slot:start>
					<Icon class="h-5 w-5" icon="angleLeft" />
				</template>
				<p>{{ $t('help.helpCenter') }}</p>
			</Toolbar>
			<div class="inline-flex max-h-max w-full flex-col gap-5 overflow-scroll px-5 pt-5 pb-20 disable-scrollbars">
				<div v-for="(question, index) in questions" :key="`question-${index}`"
					class="inline-grid grid-cols-8 rounded-xl bg-light-grey p-3" @click="onSelect(index)">
					<div class="col-span-7 text-secondary">
						<div class="w-full text-lg font-black">{{ question.name }}</div>
					</div>

					<div class="col-span-1 inline-flex items-center justify-center text-secondary">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24"
							stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
						</svg>
					</div>
				</div>
			</div>
		</div>
	</BaseLayout>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
// import { useStore } from 'vuex'
import Modal from '@/components/Modal'
import Toolbar from '@/components/Toolbar'
import { useI18n } from 'vue-i18n'
import { useSharedStore } from '@/store/shared'

export default {
	components: {
		Modal,
		Toolbar,
	},

	setup() {
		// const store = useStore()
		const { locale } = useI18n()
		const { config } = storeToRefs(useSharedStore())

		const questions = config.value.questions[locale.value]
		// const questions = computed(() => store.getters.questions)
		const question = ref(null)
		const showModal = ref(false)

		function onSelect(index) {
			question.value = questions[index]
			showModal.value = true
		}

		function onClose() {
			question.value = null
			showModal.value = false
		}

		return {
			questions,
			question,
			showModal,
			onSelect,
			onClose,
		}
	},
}
</script>
